<template>
	<div>

		<div class="topsec">
			<div v-if="upcomingNotices.length > 0" class="upcoming-notices">
				<div class="n-head-hold">
					<p class="n-heading">
						<i class="mdi mdi-calendar-month-outline"></i>
						What's Coming Up
					</p>
				</div>
				<div class="n-section-contain">
					<ul class="arn-ul">
						<li v-for="notice in upcomingNotices" :key="notice.ai">
							<span class="bold upitem">{{notice.nicedate}}:</span>
							<span class="">{{notice.item_name}}</span>
						</li>
					</ul>
				</div>
			</div>

			<div class="upcoming-notices">
				<div class="n-head-hold">
					<p class="n-heading">ARN Research & Intelligence Products</p>
				</div>
				<div class="n-section-contain">
					<ul class="arn-ul">
						<li><a href="https://ausradio.sharepoint.com/departments/SRI/Commercial%20Collateral/Forms/AllItems.aspx?id=%2Fdepartments%2FSRI%2FCommercial%20Collateral%2FResearch%20%26%20Insights%2FAudience%20Overviews&viewid=19767fcb%2D2caa%2D4e81%2Dad5a%2Dbadbe7d4790e" target="_blank">Metro audience overview</a></li>
						<li><a href="https://ausradio.sharepoint.com/departments/SRI/Commercial%20Collateral/Forms/AllItems.aspx?id=%2Fdepartments%2FSRI%2FCommercial%20Collateral%2FARN%20Regional%2FStation%20Profiles&viewid=19767fcb%2D2caa%2D4e81%2Dad5a%2Dbadbe7d4790e" target="_blank">Regional audience overview</a></li>
						<li><a href="https://ausradio.sharepoint.com/departments/SRI/Commercial%20Collateral/Forms/AllItems.aspx?id=%2Fdepartments%2FSRI%2FCommercial%20Collateral%2FResearch%20%26%20Insights%2FBackstage&viewid=19767fcb%2D2caa%2D4e81%2Dad5a%2Dbadbe7d4790e" target="_blank">Backstage</a></li>
						<li><a href="https://ausradio.sharepoint.com/departments/SRI/Commercial%20Collateral/Forms/AllItems.aspx?id=%2Fdepartments%2FSRI%2FCommercial%20Collateral%2FResearch%20%26%20Insights%2FNeuroLab&viewid=19767fcb%2D2caa%2D4e81%2Dad5a%2Dbadbe7d4790e" target="_blank">Neurolab</a></li>
						<li><a href="https://ausradio.sharepoint.com/departments/SRI/Commercial%20Collateral/Forms/AllItems.aspx?id=%2Fdepartments%2FSRI%2FCommercial%20Collateral%2FResearch%20%26%20Insights%2FAustralia%20Unplugged&viewid=19767fcb%2D2caa%2D4e81%2Dad5a%2Dbadbe7d4790e" target="_blank">Australia Unplugged</a></li>
					</ul>
				</div>
			</div>

		</div>

		<div class="audiosphere-section">
			<p class="n-heading">ARN Audiosphere</p>
			<div class="audiosphere-flex">
				<div class="audiosphere-half">
					<img src="/public-files/audiosphere-2024.png" />
				</div>
				<div class="audiosphere-text-half">
					<p>
						Offering the greatest depth & breadth of audio experiences, the ARN Audiosphere is Australia’s first, most complete audio offering.
						<br/><br/>
						ARN’s extensive product suite spans across our Audiosphere and for advertisers, ARN’s audio solutions seek to leverage the whole Audiosphere, providing audio entertainment, experiential connections, audio messaging, dynamic creative and campaign amplification.
						<br/><br/>
						Please find below resources available that may help you build out your campaigns using the ARN Audiosphere:
						<br/><br/>
					</p>

					<div>
						<ul class="arn-ul">
							<li><a href="https://ausradio.sharepoint.com/:p:/r/departments/SRI/_layouts/15/Doc.aspx?sourcedoc=%7BDD22C6FF-A6A1-4E30-83A9-FC03245095E7%7D&file=ARN%20at%20Scale%20-%20Survey%207%202024.pptx&action=edit&mobileredirect=true" target="_blank">ARN at Scale - Total Network Figures</a></li>
<!--							<li><a href="https://ausradio.sharepoint.com/:x:/r/departments/SRI/_layouts/15/Doc.aspx?sourcedoc=%7BF987ED86-F996-4E5A-868D-724476D590C7%7D&file=Audiosphere%20Segment%20Inclusions.xlsx&action=default&mobileredirect=true" target="_blank">Audiosphere Segment Inclusions</a></li>-->
							<li><a href="https://ausradio.sharepoint.com/:p:/r/departments/SRI/Commercial Collateral/Templates/PowerPoint Templates/Audiosphere Template_Nov24.pptx?d=w89003acf8904421a882d405cd55d4378&csf=1&web=1&e=VunabH" target="_blank">Audiosphere Template</a></li>
							<li><a href="https://ausradio.sharepoint.com/departments/SRI/Commercial%20Collateral/Forms/AllItems.aspx?id=%2Fdepartments%2FSRI%2FCommercial%20Collateral%2FResearch%20%26%20Insights%2FCase%20Studies&viewid=19767fcb%2D2caa%2D4e81%2Dad5a%2Dbadbe7d4790e" target="_blank">Case Studies</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="n-section">
			<p class="n-heading">Additional Useful Insight Tools</p>
			<div class="cols">
				<div>
					<div class="n-cont">
						<a class="n-title-link" target="_blank" href="https://www.warc.com/">WARC</a>
						<p class="subtext">Provides insight, intelligence, evidence, expertise, case studies, benchmarks and guidance to help marketers</p>
					</div>
					<div class="n-cont">
						<a class="n-title-link" target="_blank" href="https://www.ibisworld.com/">IBIS World</a>
						<p class="subtext">Provides trusted industry research on thousands of industries worldwide</p>
					</div>
					<div class="n-cont">
						<a class="n-title-link" target="_blank" href="https://platform.audology.app/">Audology</a>
						<p class="subtext">Audology reports on official radio ratings data, overseen by CRA</p>
					</div>
					<div class="n-cont">
						<a class="n-title-link" target="_blank" href="https://app.aircheck.net.au/v2/app/">Aircheck</a>
						<p class="subtext">Market & competitive analysis of all radio activity</p>
					</div>
					<div class="n-cont">
						<a class="n-title-link" target="_blank" href="https://apps.arn.com.au/RDWeb/Pages/en-US/login.aspx?ReturnUrl=/RDWeb/Pages/en-US/Default.aspx/Applications">Nielsen Clear Decisions</a>
						<p class="subtext">Consumer insights and analysis</p>
					</div>
				</div>
				<div>
					<div class="n-cont">
						<a class="n-title-link" target="_blank" href="https://www.commercialradio.com.au/">Commercial Radio & Audio</a>
						<p class="subtext">Peak industry body representing the interests of commercial radio broadcasters throughout Australia</p>
					</div>
					<div class="n-cont">
						<a class="n-title-link" target="_blank" href="https://www.commercialradio.com.au/RA/media/General/Documents/Research%20Documents/GfK_Radio-Ratings-Tool-Kit_2022.pdf?ext=.pdf">GFK Radio Ratings Toolkit</a>
						<p class="subtext">Provides all the information you require regarding the GfK radio ratings</p>
					</div>
					<div class="n-cont">
						<a class="n-title-link" target="_blank" href="https://console.tritondigital.com/login.jsp;jsessionid=1j0to74rad81vfema0haqg5p2">Triton Podcast Audience Measurement</a>
						<p class="subtext">Configure and manage the different parameters of your podcast audience</p>
					</div>
					<div class="n-cont">
						<a class="n-title-link" target="_blank" href="https://apps.arn.com.au/RDWeb/Pages/en-US/login.aspx?ReturnUrl=/RDWeb/Pages/en-US/Default.aspx/Applications">Fusion Eram & AQX</a>
						<p class="subtext">Advertising expenditure analysis and GFK radio audience analysis</p>
					</div>
					<div class="n-cont">
						<a class="n-title-link" target="_blank" href="https://adintel.au.nielsen.com/adintel/login.aspx">Nielsen AdIntel</a>
						<p class="subtext">Marketing expenditure analysis</p>
					</div>
				</div>
			</div>
		</div>

		<div class="topsec">
			<div class="upcoming-notices">
				<div class="n-head-hold">
					<p class="n-heading">User Training</p>
				</div>
				<div class="n-section-contain">
					<ul class="arn-ul">
						<li><a href="https://kb.audology.app/tutorial-videos" target="_blank">Audology</a></li>
						<li><a href="https://userguides.tritondigital.com/str/sconug1/index.html" target="_blank">Triton Podcast Insight Measurement</a></li>
						<li><a href="https://ausradio.sharepoint.com/departments/SRI/Internal%20Audio%20Academy/Forms/AllItems.aspx?xsdata=MDV8MDF8fDUxZTA0ZGRjODVlYTRiOTE4ZmIwMDhkYmQxMGI4MjM3fDkxZDViYzQ3NGRmMzRhZDBhNWFlM2QzMjQ5ZWNlYWYxfDB8MHw2MzgzMzM2MTk1NjI1ODE5MzZ8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPbUV5TVROak1tTm1MV0ppWkRjdE5HSXhNUzA1T0RWbExUVXlaR1k1TkdFNE1UYzRaRjlpWldVeU9HUTVNUzA0T0dKaUxUUXpORGt0WVRoak15MDRZV1F6WVdZd1lUazFNRGRBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVFk1TnpjMk5URTFOREkyT0E9PXw1M2U1MzFiOWJhNzc0NDAzOGZiMDA4ZGJkMTBiODIzN3w1Nzk5MTVlN2ExMmQ0ZmM5YTJhYzk0N2ZiN2UwOGM5MQ%3D%3D&sdata=dm1HVXFCWDluMWR2a3ZwazYyanBQcHpCaHhBeWd6N0o5WllMMWVmdHh3az0%3D&ovuser=91d5bc47%2D4df3%2D4ad0%2Da5ae%2D3d3249eceaf1%2CNickReid%40arn%2Ecom%2Eau&OR=Teams%2DHL&CT=1697765171690&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yMzA5MjkxMjMwOCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&id=%2Fdepartments%2FSRI%2FInternal%20Audio%20Academy%2FAirCheck%20Training%2FAirCheck%5FTraining%5FVIDEO%20SECTION%20AGENDA%20%26%20LINK%2DRiarne%2Epdf&viewid=28d83189%2D953d%2D4407%2Da09c%2D2145d4079b5f&parent=%2Fdepartments%2FSRI%2FInternal%20Audio%20Academy%2FAirCheck%20Training" target="_blank">Aircheck</a></li>
						<li><a href="https://ausradio.sharepoint.com/:f:/r/sites/ARNLearningHub/Commercial/Business Intelligence/eRAM?csf=1&web=1&e=k0Ifzh" target="_blank">Fusion ERAM</a></li>
						<li><a href="https://ausradio.sharepoint.com/:f:/r/sites/ARNLearningHub/Commercial/Business%20Intelligence/AQX%20%26%20AdIntel+%20(Media%20Expenditure%20Reports)?csf=1&web=1&e=gaMQTQ" target="_blank">Media Expenditure (Nielsen AQX & AdIntel+)</a></li>
						<li><a href="https://ausradio.sharepoint.com/:f:/r/sites/ARNLearningHub/Commercial/Business Intelligence/CMV Nielsen?csf=1&web=1&e=JyQdyC" target="_blank">Nielsen Clear Decisions</a></li>
					</ul>
				</div>
			</div>

			<div class="upcoming-notices">
				<div class="n-head-hold">
					<p class="n-heading">User Support Contacts</p>
				</div>
				<div class="n-section-contain">
					<ul class="arn-ul">
						<li><a href="https://kb.audology.app/" target="_blank">Audology</a></li>
						<li><a href="https://tritondigital.my.site.com/s/?language=en_US" target="_blank">Triton Podcast Insight Measurement</a></li>
						<li><a href="mailto:info@aircheck.net.au" target="_blank">Aircheck</a></li>
						<li><a href="mailto:lj.support@nielsen.com" target="_blank">Fusion ERAM & AQX</a></li>
						<li><a href="mailto:aucustomersuccess@nielsen.com" target="_blank">Nielsen Clear Decisions</a></li>
					</ul>
				</div>
			</div>
		</div>

		<div class="n-section" style="margin-top: 20px;">
			<p class="n-heading">New to Radio?</p>
			<div class="">
				<a class="large-link" href="https://ausradio.sharepoint.com/sites/ARNLearningHub/" target="_blank">
					ARN's 'Survival guide' covers off all of the essentials to get you started
				</a>
			</div>
		</div>

		<div class="n-section" style="margin-bottom: 50px;">
			<p class="n-heading">Cannot find what you are looking for?</p>
			<div class="">
				<a class="large-link" href="mailto:businessintelligence@arn.com.au">businessintelligence@arn.com.au</a>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";

export default {
	name: 'HomeNoticeboard',
	components: {

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			upcomingNotices: [],
		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	props: {

	},
	mounted() {
		let self = this
		axios.get(
			"/api/home-notices.php"
		).then(function (response) {
			let ret = response.data //response.data is returned info
			if(ret.upcoming) self.upcomingNotices = ret.upcoming
		}).catch(function (error) {
			console.log(error)
		});
	},
	created() {

	},
}
</script>

<style scoped>
.n-heading {
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 10px;
}
.n-heading i {
	margin-right: 10px;
}
.topsec {
	display: flex;
	gap: 50px;
}
.upcoming-notices {
	margin: 20px 0 30px;
	border: 1px solid #DDD;
	background: #FFF;
	width: 48%;
}
.n-head-hold {
	padding: 10px 20px;
	background: #EEE;
	border-bottom: 1px solid #DDD;
}
.n-head-hold .n-heading {
	margin-bottom: 0;
}
.n-section-contain {
	padding: 20px 30px;
	background: #FFF;
}
.upitem {
	margin-right: 5px;
}
.arn-ul {
	list-style-type: disc;
	list-style-position: inside;
	line-height: 1.6;
	font-size: 15px;
}
.arn-ul a {
	color: #4E39A3;
	text-decoration: underline;
}
.arn-ul a:hover {
	color: #000;
}
.audiosphere-section {
	margin-top: 10px;
}
.audiosphere-flex {
	display: flex;
	padding: 30px 0;
	align-content: center;
	gap: 80px;
}
.audiosphere-text-half {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 800px;
	font-size: 18px;
}
.audiosphere-half img {
	height: 500px;
}
.cols {
	display: flex;
	gap: 50px;
	margin-top: 20px;
}
.cols > div {
	width: 48%;
}
.n-section {
	margin: 50px 0 20px;
}
.n-cont {
	border: 1px solid #DDD;
	padding: 20px;
	margin-bottom: 20px;
	background: #FFF;
}
.n-title-link {
	font-size: 18px;
	font-weight: bold;
	color: #4E39A3;
	margin-bottom: 10px;
	display: block;
}
.n-title-link:hover {
	color: #000;
}
.large-link {
	font-size: 16px;
	font-weight: bold;
	color: #4E39A3;
	margin-bottom: 10px;
	display: block;
}
.large-link:hover {
	color: #000;
}

@media screen and (max-width: 1300px) {
	.audiosphere-half img {
		height: 350px;
	}
	.audiosphere-text-half {
		font-size: 16px;
	}
}
@media screen and (max-width: 1100px) {
	.cols, .topsec {
		flex-wrap: wrap;
		gap: unset;
	}
	.cols > div, .upcoming-notices {
		width: 100%;
	}
}
@media screen and (max-width: 1000px) {
	.audiosphere-half img {
		height: 250px;
	}
}
@media screen and (max-width: 9000px) {
	.audiosphere-flex {
		flex-wrap: wrap;
	}
	.audiosphere-half img {
		height: auto;
		max-width: 100%;
	}
}
</style>
