<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>

		<div v-if="userHasPermission(viewPerms)" class="top-selections">
			<div>
				<p class="above-select-label">Custom Event</p>
				<select class="select-styled" v-model="chosenCustomEventType">
					<option v-for="opt in eventOptions" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div style="width: 140px;">
				<p class="above-select-label">Start Date</p>
				<date-picker class="dater-tr" style="width: 140px;" v-model:value="startdate" valueType="format" format="YYYY-MM-DD"></date-picker>
			</div>
			<div style="width: 140px;">
				<p class="above-select-label">End Date</p>
				<date-picker class="dater-tr" style="width: 140px;" v-model:value="enddate" valueType="format" format="YYYY-MM-DD"></date-picker>
			</div>
			<div style="display: flex; align-items: flex-end;">
				<span class="primary-btn" @click="getChartData">Fetch</span>
			</div>
		</div>

		<div class="chart-rows">
			<ChartHolderDash
					:charttitle="chartsTitle"
					:source="chartsSource" :height="600">
				<template v-slot:default>
					<ApexLineWeeklyTrend :forcelinetype="false" :chartdata="chartsData" filename="BrazeCustomEvents" :fetching="getChartsBtnWorking"></ApexLineWeeklyTrend>
				</template>
			</ChartHolderDash>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ApexLineWeeklyTrend from "@/components/charts/ApexLineWeeklyTrend";
import PermissionChecker from "@/components/PermissionChecker";
import 'vue-datepicker-next/index.css';
import DatePicker from "vue-datepicker-next";

export default {
	name: 'IHRBrazeCustomEvents',
	components: {
		DatePicker,
		PermissionChecker,
		ApexLineWeeklyTrend,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-metro','connections','iheart'],
			metaReceived: false,
			getChartsBtnWorking: false,
			chartsData: [],
			chartsSource: '',
			chartsTitle: '',

			chosenCustomEventType: 1,
			startdate: null,
			enddate: null,

			eventOptions: [
				{id: 1, name: 'Registrations'},
				{id: 2, name: 'Stream Start'},
				{id: 6, name: 'Streamed Live'},
				{id: 8, name: 'Streamed Podcast'},
				{id: 3, name: 'Streamed Album'},
				{id: 4, name: 'Streamed Custom'},
				{id: 5, name: 'Streamed Favourites'},
				{id: 7, name: 'Streamed Playlist'},
			],

		}
	},
	computed: {

	},
	methods: {
		getChartData() {
			this.getChartsBtnWorking = true;
			let str = "eventId="+this.chosenCustomEventType+"&cadence=d&startdate=" + this.startdate + "&enddate=" + this.enddate;
			let self = this;
			let targ = "/api/iheart/braze-custom-events.php?"+str;
			this.chartsData = [];
			axios.get(targ).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.chartsData != null) self.chartsData = ret.chartsData
					if (ret.chartsSource != null) self.chartsSource = ret.chartsSource
					if (ret.chartsTitle != null) self.chartsTitle = ret.chartsTitle
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
		// getMeta() {
		// 	var self = this;
		// 	var targ = "/api/iheart/get-meta.php";
		// 	axios.get(targ).then(function (response) {
		// 		var ret = response.data; //response.data is returned info
		// 		if (ret.error === 1) { console.log(ret); }
		// 		else {
		// 			if (ret.appinfo != null) {
		// 				var a = ret.appinfo;
		// 				self.stations = a['ihrStations'];
		// 				self.segments = a['ihrSegments'];
		// 				self.monthstarts = a['streamingMonthStarts'];
		// 				self.monthends = a['streamingMonthEnds'];
		// 				self.weekstarts = a['streamingWeekStarts'];
		// 				self.weekends = a['streamingWeekEnds'];
		// 				self.startdayselect = a['streamingDefaultMonthStart'];
		// 				self.enddayselect = a['streamingDefaultMonthEnd'];
		// 				self.defaultWeekStart = a['streamingDefaultWeekStart'];
		// 				self.defaultWeekEnd = a['streamingDefaultWeekEnd'];
		// 				self.defaultMonthStart = a['streamingDefaultMonthStart'];
		// 				self.defaultMonthEnd = a['streamingDefaultMonthEnd'];
		// 				self.metaReceived = true
		// 				self.getChartData()
		// 			}
		// 		}
		// 	}).catch(function (error) {
		// 		console.log(error);
		// 	});
		// },
	},
	watch: {
		chosenCustomEventType() {
			this.getChartData()
		},
	},
	mounted() {
		let d = new Date()
		d.setDate(d.getDate() - 1) //set end date to yesterday
		this.enddate = d.getFullYear()+'-'+this.ldzero(d.getMonth()+1)+"-"+this.ldzero(d.getDate())
		let mt = d.getMonth()
		let yr = d.getFullYear()
		if(mt === 0) {
			mt = 12
			yr--
		}
		this.startdate = yr+'-'+this.ldzero(mt)+"-01"
		this.getChartData()
	},
	created() {
		document.title = "IHR Braze Custom Events";
	},
}
</script>

<style scoped>
.top-selections {
	display: flex;
	justify-content: flex-start;
	gap: 15px;
}

@media screen and (max-width: 1450px) {
	.top-selections {
		flex-wrap: wrap;
	}
}
</style>
