<template>
	<div v-if="showoverlay" class="loader-overlay">
		<div class="overlay-parent" :style="heightStr">
			<div class="overlay">
				<div class="popup-topbar">
					<span class="boldme">{{title}}</span>
					<span class="closer" @click="$emit('close')">
						<i class="mdi mdi-close"></i>
					</span>
				</div>
				<div class="popup-insides">
					<slot name="default"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: 'PopupOverlay',
	components: {

	},
	props: {
		showoverlay: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: 'Chart'
		},
		height: {
			type: String,
			default: null
		}
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {
		heightStr() {
			if (this.height !== null) {
				return 'height: '+this.height
			}
			return ''
		}
	},
	methods: {},
	watch: {},
	mounted() {

	},
	created() {
		// let urlparams = this.$route.query
		// this.heading = "ARN Connections Dashboard"
		// if (urlparams.errorId !== undefined) {
		// 	this.errorId = parseInt(urlparams.errorId)
		// }
		// document.title = this.heading
	},
}
</script>

<style scoped>
.overlay-parent {
	width: 90%;
	height: 90%;
	position: fixed;
	top: 5%;
	left: 5%;
}
.overlay {
	display: flex;
	background-color: #FFF;
	overflow-y: auto;
	height: 100%;
}
.popup-topbar {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgb(239, 239, 239);
	border-bottom: 1px solid rgb(204, 204, 204);
	color: #000;
	padding: 0 20px;
	width: 100%;
	height: 40px;
}
.closer {
	font-size: 18px;
	cursor: pointer;
}
.closer:hover i {
	color: #888;
}
.popup-insides {
	width: 100%;
	padding: 20px;
	height: calc(100% - 40px);
	margin-top: 40px;
	overflow-y: auto;
}
</style>
