<template>
	<div>

		<div class="header-notes">
			<p>
				Chaplin's iHeartRadio dashboard can be accessed from the <span v-if="userHasPermission(['elt','connections','content-metro','iheart'])">first</span> link below.
				That page is behind the ARN network security - you will either need to be in an ARN building or connected
				to the ARN VPN.
			</p>
			<p v-if="userHasPermission(['elt','connections','content-metro'])">Other pages do not require the VPN.</p>
		</div>

		<div style="padding-top: 20px; display: flex; flex-wrap: wrap;">
			<a class="large-tile-button" href="https://dashboard.arn.com.au/dashboard/iheart" target="_blank">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-database-settings-outline"></i></div>
					<p>iHeartRadio (VPN Required)</p>
				</div>
			</a>
			<div v-if="userHasPermission(['elt','connections','content-metro','iheart'])" class="large-tile-button" @click="$router.push('/iheart/dashboard')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-chart-bar-stacked"></i></div>
					<p>iHeartRadio Dashboard</p>
				</div>
			</div>
			<div v-if="userHasPermission(['elt','connections','iheart'])" class="large-tile-button" @click="$router.push('/iheart/playlists-dashboard')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-music"></i></div>
					<p>Playlists</p>
				</div>
			</div>
			<div v-if="userHasPermission(['elt','connections','content-metro','iheart'])" class="large-tile-button" @click="$router.push('/iheart/national-kpi')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-chart-timeline"></i></div>
					<p>iHeartRadio National KPIs</p>
				</div>
			</div>
<!--			<div v-if="userHasPermission(['elt','connections','content-reg','iheart'])" class="large-tile-button" @click="$router.push('/iheart/regional-summary')">-->
<!--				<div class="tile-inside">-->
<!--					<div class="tile-icon-hold"><i class="mdi mdi-chart-bell-curve-cumulative"></i></div>-->
<!--					<p>iHeartRadio Regional KPIs</p>-->
<!--				</div>-->
<!--			</div>-->
			<div v-if="userHasPermission(['elt','connections','content-metro','iheart'])" class="large-tile-button" @click="$router.push('/iheart/chart-explorer')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-chart-line"></i></div>
					<p>iHeartRadio Chart Explorer</p>
				</div>
			</div>
			<div v-if="userHasPermission(['elt','connections','iheart'])" class="large-tile-button" @click="$router.push('/iheart/braze-campaigns')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-table"></i></div>
					<p>Braze Campaign Analytics</p>
				</div>
			</div>
			<div v-if="userHasPermission(['elt','connections','iheart'])" class="large-tile-button" @click="$router.push('/iheart/braze-custom-events')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-calendar-search"></i></div>
					<p>Braze Custom Events</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'IHRLand',
	components: {},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			errorId: 0,
			heading: '',
		}
	},
	computed: {
		transformed() {
			return {
				template: '<div>' + this.content + '</div>',
				props: this.$options.props
			}
		}
	},
	methods: {},
	watch: {},
	props: {
		errorPageHeading: String,
		errorPageText: String,
	},
	mounted() {

	},
	created() {
		let urlparams = this.$route.query;
		this.heading = "ARN Connections Dashboard";
		if (urlparams.errorId !== undefined) {
			this.errorId = parseInt(urlparams.errorId);
		}
		document.title = this.heading;
	},
}
</script>

<style scoped>
.header-notes {
	margin: 20px 0;
	padding: 20px;
	border: 1px solid #DDD;
	background-color: #DDD;
	color: #444;
	font-size: 15px;
}
</style>
