<template>
	<div>

		<div class="header-notes">
			<p>Welcome to the ARN Connections Dashboard.
				If you have problems or need to request further access for yourself or staff, please contact your manager or email <a class="text-link" href="mailto:alanlogan@arn.com.au">alanlogan@arn.com.au</a>.
			</p>
		</div>

		<div>
			<HomeNoticeboard></HomeNoticeboard>
		</div>

		<div v-if="store.user.email === 'alanlogan@arn.com.au'">
			<AdminStats></AdminStats>
		</div>

		<div style="padding-top: 20px; display: flex; flex-wrap: wrap;">
			<div v-if="userHasPermission(['elt','connections','content-metro','podcast']) && !store.lockdownUserIds.includes(store.user.id)" class="large-tile-button" @click="$router.push('/podcasts/dashboard')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-headphones"></i></div>
					<p>Podcasts</p>
				</div>
			</div>
			<div class="large-tile-button" @click="$router.push('/gfk-ratings/charts')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-video-input-antenna"></i></div>
					<p>GFK Ratings</p>
				</div>
			</div>
			<div class="large-tile-button" @click="$router.push('/xtra-ratings')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-chart-line"></i></div>
					<p>Xtra Ratings</p>
				</div>
			</div>
			<div v-if="userHasPermission(['elt','content-metro','connections','content-reg'])" class="large-tile-button" @click="$router.push(streamingUserUrl)">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-antenna"></i></div>
					<p>Streaming</p>
				</div>
			</div>
			<div v-if="userHasPermission(['elt','connections','content-metro'])" class="large-tile-button" @click="$router.push('/brand-tracking/market-dashboard')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-trending-up"></i></div>
					<p>Brand Tracking</p>
				</div>
			</div>
			<div v-if="userHasPermission(['elt','content-metro','connections'])" class="large-tile-button" @click="$router.push('/brand-health/kiis1065')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-cards-heart"></i></div>
					<p>Brand Health</p>
				</div>
			</div>
			<div v-if="userHasPermission(['elt','connections'])" class="large-tile-button" @click="$router.push('/marketing')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-bullhorn"></i></div>
					<p>Marketing</p>
				</div>
			</div>
			<div class="large-tile-button" @click="$router.push('/arn-markets')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-handshake"></i></div>
					<p>ARN Markets & Stations</p>
				</div>
			</div>
			<div v-if="!store.lockdownUserIds.includes(store.user.id)" class="large-tile-button" @click="$router.push('/sales-tools')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-map-marker-outline"></i></div>
					<p>Sales Tools</p>
				</div>
			</div>
			<div v-if="!store.lockdownUserIds.includes(store.user.id)" class="large-tile-button" @click="$router.push('/post-campaign-reporting')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-sign-direction"></i></div>
					<p>Post Campaign Reporting</p>
				</div>
			</div>
<!--			<div v-if="!store.lockdownUserIds.includes(store.user.id)" class="large-tile-button" @click="$router.push('/sales-tools/aircheck-clients')">-->
<!--				<div class="tile-inside">-->
<!--					<div class="tile-icon-hold"><i class="mdi mdi-currency-usd"></i></div>-->
<!--					<p>Aircheck Client Reports</p>-->
<!--				</div>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

import AdminStats from "@/components/AdminStats";
import HomeNoticeboard from "@/components/HomeNoticeboard.vue";
// import StreamLogBugNote from "@/components/bits/StreamLogBugNote";
export default {
	name: 'HomePage',
	components: {
		HomeNoticeboard,
		// StreamLogBugNote,
		AdminStats
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			errorId: 0,
			heading: '',
		}
	},
	computed: {
		streamingUserUrl() {
			if(this.userHasPermission(['elt','connections','content-metro','content-reg'])) {
				return '/streaming/trending';
			}
			return false;
		},
		transformed() {
			return {
				template: '<div>' + this.content + '</div>',
				props: this.$options.props
			}
		}
	},
	methods: {},
	watch: {},
	props: {
		errorPageHeading: String,
		errorPageText: String,
	},
	mounted() {

	},
	created() {
		let urlparams = this.$route.query;
		this.heading = "ARN Connections Dashboard";
		if (urlparams.errorId !== undefined) {
			this.errorId = parseInt(urlparams.errorId);
		}
		document.title = this.heading;
	},
}
</script>

<style scoped>
.header-notes {
	margin: 20px 0;
	padding: 20px;
	border: 1px solid #CCC;
	background-color: #DDD;
	color: #444;
	font-size: 15px;
}
</style>
