<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">

			<div>
				<ChartHolderDash charttitle="Braze Campaign Summary" source="Braze" :height="1500">
					<template v-slot:headerRight>
						<div style="display: flex; align-items: center;">
							<div style="margin-right: 20px; width: 140px;">
								<date-picker class="dater-tr" style="width: 140px;" v-model:value="startdate" valueType="format" format="YYYY-MM-DD"></date-picker>
							</div>
							<span class="above-select-label" style="margin-right: 15px;"> to </span>
							<div style="margin-right: 20px; width: 140px;">
								<date-picker class="dater-tr" style="width: 140px;" v-model:value="enddate" valueType="format" format="YYYY-MM-DD"></date-picker>
							</div>
						</div>
					</template>
					<div class="b-table-holder">
						<ALTable tblclasses="noborderv" :excel="true" excelfilename="Braze_Campaigns"
							:cols="[
								{title: 'Campaign', sortable: true, fldid: 'name', class: 'link-text', clickaction: 'showchart'},
								{title: 'First Sent', sortable: true, fldid: 'first_sent'},
								{title: 'Last Sent', sortable: true, fldid: 'last_sent'},
								{title: 'Last Edited', sortable: true, fldid: 'last_edited'},
								{title: 'Unique Recips', sortable: true, fldid: 'unique_recipients', numtype: 'number'},
								{title: 'Conversions', sortable: true, fldid: 'conversions', numtype: 'number'},
								{title: 'Conv %', sortable: true, fldid: 'conversion_pct', numtype: 'pct'},
							]"
							:rowdata="tableData"
							@cellclick="cellClickHandle($event)"
						></ALTable>
					</div>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>
			</div>

			<PopupOverlay :showoverlay="showChartOverlay" title="Braze Campaign Delivery & Conversion By Day" @close="showChartOverlay = false" height="700px">
				<template v-slot:default>
					<div class="chart-holder">
						<ChartHolderDash :chartdata="chartData" :height="500" source="Braze" :charttitle="activeCampaignName">
							<template v-slot:default>
								<ApexLineTrendMulti :chartdata="chartData"></ApexLineTrendMulti>
							</template>
						</ChartHolderDash>
					</div>
				</template>
			</PopupOverlay>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import PermissionChecker from "@/components/PermissionChecker";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";
import ALTable from "@/components/ALTable.vue";
import PopupOverlay from "@/components/PopupOverlay.vue";
import ApexLineTrendMulti from "@/components/charts/ApexLineTrendMulti.vue";

export default {
	name: 'IHRBrazeCampaigns',
	components: {
		ApexLineTrendMulti,
		PopupOverlay,
		ALTable, ChartLoaderInsert, ChartHolderDash,
		PermissionChecker,
		DatePicker,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','connections','iheart'],

			getChartsBtnWorking: false,

			startdate: null,
			enddate: null,
			tableData: [],

			showChartOverlay: false,
			chartData: [],
			activeCampaignName: null,

		}
	},
	computed: {

	},
	methods: {
		cellClickHandle(ob) {
			let rowitem = ob.row
			let col = ob.col
			if(!Object.hasOwn(col, 'clickaction')) return
			if(col.clickaction === 'showchart') {
				let campaignId = rowitem.id
				if(campaignId) {
					this.activeCampaignName = rowitem.name
					this.getShowCampaignChart(campaignId)
				}
			}
		},
		getShowCampaignChart(campaignId) {
			//this.getChartsBtnWorking = true
			this.chartData = []
			this.showChartOverlay = true
			let targ = "/api/iheart/braze-campaign-chart.php?campaignId="+campaignId+"&startdate="+this.startdate+"&enddate="+this.enddate
			let self = this
			this.summaryData = []
			axios.get(targ).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.error === 1) { console.log(ret) }
				else {
					if (ret.chartData != null) self.chartData = ret.chartData
				}
				self.getChartsBtnWorking = false
			}).catch(function (error) {
				console.log(error)
			});
		},
		getData() {
			this.getChartsBtnWorking = true
			let targ = "/api/iheart/braze-campaign-list.php?startdate="+this.startdate+"&enddate="+this.enddate
			let self = this
			this.summaryData = []
			axios.get(targ).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.error === 1) { console.log(ret) }
				else {
					if (ret.tableData != null) self.tableData = ret.tableData
				}
				self.getChartsBtnWorking = false
			}).catch(function (error) {
				console.log(error)
			});
		},
	},
	watch: {

	},
	mounted() {
		let d = new Date()
		d.setDate(d.getDate() - 1) //set end date to yesterday
		this.enddate = d.getFullYear()+'-'+this.ldzero(d.getMonth()+1)+"-"+this.ldzero(d.getDate())
		let mt = d.getMonth()
		let yr = d.getFullYear()
		if(mt === 0) {
			mt = 12
			yr--
		}
		this.startdate = yr+'-'+this.ldzero(mt)+"-01"
		this.getData()
	},
	created() {
		document.title = "IHR Braze Campaigns";
	},
}
</script>

<style scoped>

</style>
